$(document).ready(function() {

    // navbar burger menu
    //--------------------
    $('.navbar-burger').click(function() {
        $('.navbar-burger').toggleClass('is-active')
        $('.navbar-menu').toggleClass('is-active')
    })

    // carousel
    //--------------------
    $('.owl-carousel').owlCarousel({
        center: false,
        items: 1,
        loop: true,
        stagePadding: 0,
        smartSpeed: 1000,
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        nav: true,
        navText: ['<i class="fas fa-angle-left fa-2x" aria-hidden="true"></i>', '<i class="fas fa-angle-right fa-2x" aria-hidden="true"></i>'],
        navContainer: '.custom-nav',
        animateOut: 'fadeOut',
        animateIn: 'fadeIn'
    })

    // parallax header
    //--------------------
    const siteStellar = function() {
        $(window).stellar({
            responsive: false,
            parallaxBackgrounds: true,
            parallaxElements: true,
            horizontalScrolling: false,
            hideDistantElements: false,
            scrollProperty: 'scroll'
        })
    }
    siteStellar()

    // tippy
    //--------------------
    tippy('[data-tippy-content]')

    // footer
    //--------------------
    $('.footer svg').mouseover(function() {
        const id = $(this).attr('id')
        let fill = '#fff'
        if (id === 'iwm') {
            fill = '#ff5d1f'
        } else if (id === 'uni-tuebingen') {
            fill = '#b9283e'
        }
        gsap.to(this.querySelectorAll('path'), {
            fill
        })
    })

    $('.footer svg').mouseout(function() {
        gsap.to('.footer path', {
            fill: '#fff'
        })
    })

    // AOS
    //--------------------
    AOS.init({
        duration: 1000,
        easing: 'slide',
        once: false
    })
})
